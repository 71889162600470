import React, { memo, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { bool, func, instanceOf, number, object, oneOfType, string } from 'prop-types';
import { useFilterState } from '../filterContext';
import { useCartState } from '../../../contexts/cart';
import { useCapUtils } from '../hooks/useCapUtils';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import useMedia from '../../../hooks/useMedia';
import ReadOnlyPersistantCapHeader from './ReadOnlyPersistantHeader';
import EditOnlyPersistantCapHeader from './EditOnlyPersistantCapHeader/EditOnlyPersistantCapHeader';
import Button from '../../global/atoms/button';
import PickupStore from '../pickupStore/PickupStore';
import Alert from '../../global/atoms/alert/alert';
import AlertCircle from '../../../resources/images/alert-circle-black.svg';
import { isValidString } from '../../global/utils/logger';
import { capDataLocator } from '../dataLocator';
import { EDIT_VIEW_CLOSE, EDIT_VIEW_DETAILS, EDIT_VIEW_OPEN, FULFILLMENT_TYPE } from '../constants';
import { SET_SHOW_AVS_ERROR_IN_CAP, SET_START_CLICK } from '../../../aem-core-components/actions/constants';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';

const PersistantHeader = props => {
    const {
        renderRentalDetailsView,
        renderRentalDetailsDrawerContent,
        saveCapDetailsHandler,
        isCapInteracted,
        setIsCapInteracted,
        setShowPickupStoreAlert,
        showPickupStoreAlert,
        setNoLocationPC,
        setShowAlertBanner,
        showAlertBanner,
        updateRentalViewDetails,
        addressText,
        filterStartDate,
        filterEndDate,
        selectedPickupStore,
        jobsiteDetails,
        fulfillmentValue,
        isJobsiteSelected,
        resetCapFields,
        formInteractionStartedEventAnalytics,
        pickupstoreContainerRef,
        locationContainerRef,
        sendDeliveryInteractedEvent,
        sendAnalyticsForNoStoreNearBy,
        isStoreLocationFetching,
        noLocationPC,
        locationDetails,
        setSelectedPickupStore,
        storesData,
        companyID,
        chooseStoreHandler,
        showStoresCardShimmer
    } = props;
    const capIntl = useIntl();
    const alertRef = useRef(null);
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const [{ viewCart, isEditView, projectDetails, editViewSource, startDate, endDate, showAvsErrorInCap }, dispatch] =
        useFilterState();
    const [, cartDispatch] = useCartState();
    const { isSelectedLocationEmpty } = useCheckLocationEmpty();
    const { isCapHeroComponent } = useCapUtils();
    const mediaType = useMedia();
    const isCapHero = isCapHeroComponent();
    const isPickupStoreVisible = Boolean(
        !isCapHero && locationDetails?.lat && fulfillmentValue === FULFILLMENT_TYPE.PICKUP
    );

    useEffect(() => {
        if (isValidString(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CAP_IN_EDIT_MODE))) {
            dispatch({ type: EDIT_VIEW_OPEN });
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CAP_IN_EDIT_MODE);
        }
    }, []);

    useEffect(() => {
        // will not show avs error in cap once user changes from edit view to read view
        if (!isEditView && showAvsErrorInCap) {
            dispatch({ type: SET_SHOW_AVS_ERROR_IN_CAP, showAvsErrorInCap: false });
        }
    }, [isEditView]);

    useEffect(() => {
        const getHeader = ENV_CONFIG.HEADER;
        getHeader?.classList?.add('header--no-shadow');
        const breadcrumbPaddingTop = ENV_CONFIG.MAIN_SECTION;
        if (showAlertBanner) {
            breadcrumbPaddingTop?.classList?.add('breadcrumb-padding-top');
            alertRef?.current?.focus();
        } else {
            breadcrumbPaddingTop?.classList?.remove('breadcrumb-padding-top');
        }
    }, [showAlertBanner]);

    useEffect(() => {
        // open condition to check that the click is not done from minicart
        if (editViewSource == VARIABLE_CONFIG.CAP_VIEW_MODE.DATE_VIEW) {
            setIsCapInteracted(true);
            dispatch({ type: EDIT_VIEW_CLOSE });
        }
    }, [editViewSource]);

    const toggleViewMode = () => {
        setIsCapInteracted(false);
        dispatch({ type: EDIT_VIEW_OPEN });
        dispatch({ type: EDIT_VIEW_DETAILS, editViewSource: '' });
    };

    const toggleEditMode = () => {
        dispatch({ type: EDIT_VIEW_CLOSE });
        setIsCapInteracted(false);
        resetCapFields();
    };

    const handleDateOverlay = () => {
        setIsCapInteracted(true);
        dispatch({ type: EDIT_VIEW_DETAILS, editViewSource: VARIABLE_CONFIG.CAP_VIEW_MODE.DATE_VIEW });
        cartDispatch({ type: SET_START_CLICK });
    };

    const handleLocationOverlay = () => {
        dispatch({ type: EDIT_VIEW_OPEN });
        dispatch({ type: EDIT_VIEW_DETAILS, editViewSource: VARIABLE_CONFIG.CAP_VIEW_MODE.LOCATION_VIEW });
        formInteractionStartedEventAnalytics(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LOCATION);
    };

    const capHeaderOverlayHandler = () => {
        dispatch({ type: EDIT_VIEW_CLOSE });
        setIsCapInteracted(false);
        resetCapFields();
    };

    const renderAlertBanner = () => {
        if (showAlertBanner && !overridePC?.pc && (mediaType === MEDIA_TYPE.DESKTOP || !isEditView)) {
            return (
                <div
                    ref={alertRef}
                    tabIndex="0"
                    className={isEditView ? 'edit__pickupStore__alert' : 'view__pickupStore__alert'}>
                    <div
                        className={
                            isEditView
                                ? 'edit__pickupStore__alert__container'
                                : 'view__pickupStore__alert__container container'
                        }>
                        <AlertCircle className="alertCircle__icon" />
                        <div>
                            {capIntl.formatMessage({ id: 'cap:pickup-no-store-warning-text-prefix' })}
                            <a
                                href={capIntl.formatMessage({ id: 'cap:no-address-helpline-ref' })}
                                className="telephone__link"
                                title={capIntl.formatMessage({ id: 'cap:no-store-help-text' })}
                                aria-label={`${capIntl.formatMessage({
                                    id: 'cap:pickup-no-store-warning-text-prefix'
                                })} ${capIntl.formatMessage({ id: 'cap:no-address-helpline' })} ${capIntl.formatMessage(
                                    {
                                        id: 'cap:pickup-no-store-warning-text-sufix'
                                    }
                                )}`}>
                                {capIntl.formatMessage({ id: 'cap:no-address-helpline' })}
                            </a>
                            {capIntl.formatMessage({ id: 'cap:pickup-no-store-warning-text-sufix' })}
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderAlertBannerInDrawer = () => {
        if (showAlertBanner && !overridePC?.pc) {
            return (
                <Alert
                    localStyle="pickupStore__alert"
                    type={'warning'}
                    message={
                        <div>
                            {capIntl.formatMessage({ id: 'cap:pickup-no-store-warning-text-prefix' })}
                            <a
                                href={capIntl.formatMessage({ id: 'cap:no-address-helpline-ref' })}
                                className="pickupStore__alert__link"
                                title={capIntl.formatMessage({ id: 'cap:no-store-help-text' })}>
                                {capIntl.formatMessage({ id: 'cap:no-address-helpline' })}
                            </a>
                            {capIntl.formatMessage({ id: 'cap:pickup-no-store-warning-text-sufix' })}
                        </div>
                    }
                    dataTestId={capDataLocator.pickupStore_alert}
                />
            );
        }
    };

    const renderPickupStore = () => {
        if (isPickupStoreVisible) {
            return (
                <PickupStore
                    locationDetails={locationDetails}
                    selectedPickupStore={selectedPickupStore}
                    setSelectedPickupStore={setSelectedPickupStore}
                    storesData={storesData}
                    isStoresLoading={isStoreLocationFetching}
                    localStartDate={filterStartDate}
                    pickupstoreContainerRef={pickupstoreContainerRef}
                    companyId={companyID}
                    chooseStoreHandler={chooseStoreHandler}
                    showStoresCardShimmer={showStoresCardShimmer}
                />
            );
        }
    };

    const renderPersistentDrawerView = () => (
        <>
            {renderRentalDetailsDrawerContent()}
            {renderPickupStore()}
            {renderAlertBannerInDrawer()}
        </>
    );

    const renderPersistentRentalView = () => (
        <>
            {renderRentalDetailsView()}
            {renderPickupStore()}
        </>
    );

    const renderUpdateBtn = () => {
        const showShimmer = isStoreLocationFetching && isEditView;
        let buttonLabel = capIntl.formatMessage({ id: 'cap:common-apply' });

        return (
            <Button
                type="button"
                className={`start-renting-btn ${showShimmer ? 'shimmer start-renting-btn__shimmer' : ''}`}
                buttonAriaLabel={buttonLabel}
                dataTestid={capDataLocator.apply_button_cta_testid}
                isdisabled={showShimmer}
                onClick={() => saveCapDetailsHandler(noLocationPC)}>
                {buttonLabel}
            </Button>
        );
    };

    return (
        <>
            <button
                className={`modal__root ${isEditView ? 'modal__root-active' : 'modal__root-inactive'}`}
                onClick={capHeaderOverlayHandler}>
                <span className="visually-hidden">{capIntl.formatMessage({ id: 'cap:overlay-text' })}</span>
            </button>
            <EditOnlyPersistantCapHeader
                renderUpdateBtn={renderUpdateBtn}
                renderRentalDetailsView={renderPersistentRentalView}
                renderRentalDetailsDrawerContent={renderPersistentDrawerView}
                toggleEditMode={toggleEditMode}
                isCapInteracted={isCapInteracted}
                isEditView={isEditView}
                setShowPickupStoreAlert={setShowPickupStoreAlert}
                showPickupStoreAlert={showPickupStoreAlert}
                setNoLocationPC={setNoLocationPC}
                setShowAlertBanner={setShowAlertBanner}
                showAlertBanner={showAlertBanner}
                updateRentalViewDetails={updateRentalViewDetails}
                sendAnalyticsForNoStoreNearBy={sendAnalyticsForNoStoreNearBy}
                capHeaderOverlayHandler={capHeaderOverlayHandler}
            />

            <ReadOnlyPersistantCapHeader
                toggleViewMode={toggleViewMode}
                selectedStoreDetails={selectedPickupStore}
                handleDateOverlay={handleDateOverlay}
                handleLocationOverlay={handleLocationOverlay}
                startDate={filterStartDate}
                endDate={filterEndDate}
                addressText={addressText}
                jobsiteDetails={jobsiteDetails}
                fulfillmentValue={fulfillmentValue}
                isJobsiteSelected={isJobsiteSelected}
                isEditView={!isEditView && !isCapInteracted}
                formInteractionStartedEventAnalytics={formInteractionStartedEventAnalytics}
                sendDeliveryInteractedEvent={sendDeliveryInteractedEvent}
                pickupstoreContainerRef={pickupstoreContainerRef}
                locationContainerRef={locationContainerRef}
                isStoreLocationFetching={isStoreLocationFetching}
                showAlertBanner={showAlertBanner}
            />

            {renderAlertBanner()}
        </>
    );
};

PersistantHeader.defaultProps = {
    setIsCapInteracted: () => {},
    isCapInteracted: false,
    renderRentalDetailsView: () => {},
    renderRentalDetailsDrawerContent: () => {},
    saveCapDetailsHandler: () => {},
    showPickupStoreAlert: false,
    setShowPickupStoreAlert: () => {},
    setNoLocationPC: () => {},
    setShowAlertBanner: () => {},
    showAlertBanner: false,
    updateRentalViewDetails: () => {},
    addressText: '',
    filterStartDate: '',
    filterEndDate: '',
    selectedPickupStore: {},
    jobsiteDetails: {},
    fulfillmentValue: '',
    isJobsiteSelected: false,
    resetCapFields: () => {},
    formInteractionStartedEventAnalytics: () => {},
    sendDeliveryInteractedEvent: () => {},
    sendAnalyticsForNoStoreNearBy: () => {},
    isStoreLocationFetching: false,
    noLocationPC: false,
    locationDetails: {},
    setSelectedPickupStore: () => {},
    storesData: null,
    companyID: 1,
    chooseStoreHandler: () => {},
    showStoresCardShimmer: false
};

PersistantHeader.propTypes = {
    setIsCapInteracted: func,
    isCapInteracted: bool,
    renderRentalDetailsView: func,
    renderRentalDetailsDrawerContent: func,
    saveCapDetailsHandler: func,
    showPickupStoreAlert: bool,
    setShowPickupStoreAlert: func,
    setNoLocationPC: func,
    setShowAlertBanner: func,
    showAlertBanner: bool,
    updateRentalViewDetails: func,
    addressText: string,
    filterStartDate: oneOfType([instanceOf(Date), string]),
    filterEndDate: oneOfType([instanceOf(Date), string]),
    selectedPickupStore: object,
    jobsiteDetails: object,
    fulfillmentValue: string,
    isJobsiteSelected: bool,
    resetCapFields: func,
    formInteractionStartedEventAnalytics: func,
    sendDeliveryInteractedEvent: func,
    sendAnalyticsForNoStoreNearBy: func,
    isStoreLocationFetching: bool,
    noLocationPC: bool,
    locationDetails: object,
    setSelectedPickupStore: func,
    storesData: object,
    companyID: number,
    chooseStoreHandler: func,
    showStoresCardShimmer: bool
};

export default memo(PersistantHeader);
